import React from "react"
import { Link } from "gatsby"

import "./pager.scss"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext
  return (
    <nav className="pager">
      <div>
        {previousPagePath && (
          <Link to={previousPagePath} className="button">
            Mais recentes
          </Link>
        )}
      </div>

      <div className="old">
        {nextPagePath && (
          <Link to={nextPagePath} className="button">
            Mais antigos
          </Link>
        )}
      </div>
    </nav>
  )
}

export default Pager
