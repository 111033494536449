import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Pager from "../components/Pager"
import Article from "../components/Article"
import SEO from "../components/seo"

const BlogArchive = ({ data, pageContext, location }) => {
  const posts = data.allMdx.edges

  return (
    <Layout location={location}>
      <SEO title="Todos os posts | Leituras por Flavia Nunes" />
      <Header title="Todos os posts" subtitle="" />
      <section className="article-container">
        {posts.map(({ node }) => {
          const post = {
            ...node.frontmatter,
            ...node.fields,
            readingTime: node.timeToRead,
            image: node.frontmatter.cover.childImageSharp.fluid,
          }

          return (
            <div key={node.fields.slug}>
              <Article {...post} />
            </div>
          )
        })}
      </section>

      <Pager pageContext={pageContext} />
    </Layout>
  )
}

export default BlogArchive

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            title
            description
            cover {
              childImageSharp {
                fluid(maxWidth: 820, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          timeToRead
        }
      }
    }
  }
`
